import * as React from "react";
import { useState } from "react";
import "../components/css/setting-modal.css";
import { withPrefix } from "gatsby";
import Swal from 'sweetalert2';
import { useDispatch, useSelector } from 'react-redux';
import { updateUserEmail, selectAuthState } from "../sagas/auth/authSlice";

const currentUser =
  typeof window !== "undefined" &&
  JSON.parse(localStorage.getItem("currentUser") || "{}");

const SettingsModal = ({ state }) => {
  const dispatch = useDispatch();
  const { isUpdatingUserEmailSuccess, isUpdatingUserEmailFailed, UpdateUserEmailResponse } = useSelector(selectAuthState);

  const [passwordShown1, setpasswordShown1] = useState(false);
  const [passwordShown2, setpasswordShown2] = useState(false);
  const [formField, updateFormField] = useState({
    password: '',
    confirm_password: '',
    changingEmail: '',
  });

  React.useEffect(() => {
    updateFormField({
      ...formField,
      changingEmail: state.email,
    });
  }, [state.email])

  const validateField = ({ name, value }) => {
    updateFormField({
      ...formField,
      [name]: value,
    });
  };

  const onFormFieldChange = (e) => {
    const { name, value } = e.target;
    if ("password" || name === "confirm_password") {
      validateField({ name, value });
      return;
    }
  };

  const onUpdate = () => {
    //return if user is logged in with Google/Apple/Discord
    const email_check = checkEmailProvider(currentUser.email);
    if (email_check) return;
    // need to integrate password update API when available
    console.log("formField", formField);
  };

  const clearFormField = () => {
    updateFormField({
      ...formField,
      password: "",
      confirm_password: "",
    });
    setpasswordShown1(false);
    setpasswordShown2(false);
  };

  const checkEmailProvider = (email) => {
    console.log(email);
    var providers = ["google", "apple", "discord"];
    var domain = email.split("@")[1].toLowerCase();
    return providers.some(function (provider) {
      return domain.includes(provider);
    });
  };

  const onSubmitEmailClicked = (e) => {
    Swal.fire({
      title: 'Are you sure?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Confirm'
    }).then(async (result) => {
      if (result.isConfirmed) {
        dispatch({
          type: updateUserEmail.type,
          payload: { updated_email: formField.changingEmail }
        });
      }
    });
  };

  React.useEffect(() => {
    if (isUpdatingUserEmailSuccess) {
      currentUser.email = UpdateUserEmailResponse.email;
      localStorage.removeItem("currentUser");
      localStorage.setItem("currentUser", JSON.stringify(currentUser));
    }
  }, [isUpdatingUserEmailSuccess, UpdateUserEmailResponse])

  return (
    <div className="profile-modal">
      <div className="modal fade" id="setting" role="dialog">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="profile-popup">
              <div className="box">
                <div className="icon-user">
                  <img src={state.profileImage ? `${state.profileImage}?version=104` : withPrefix("assets/img/icon-user.svg")} alt="icon-user" style={{ borderRadius: '104px', maxWidth: 104, maxHeight: 104 }} />
                </div>
                <h3>Settings</h3>
                <div className="form-field alt">
                  <ul>
                    <li>
                        <div className="input-box">
                            <label>Email</label>
                            <div
                              className="password-eye"
                              onClick={onSubmitEmailClicked}
                              style={{ width: 100 }}
                            >
                              <div className="update-btn" style={{ top: '-14px', paddingLeft: '10px' }}>
                                <input id="update" name="update" type="submit" value="Update" />
                              </div>
                            </div>
                            <div className="input-out">
                                <div className="input-password-btn">
                                    <input id="email" style={{ borderRadius: '5px' }} name="changingEmail" type="text" onChange={onFormFieldChange} value={formField.changingEmail} />
                                </div>
                            </div>
                        </div>
                    </li>
                    <li>
                      <div className="input-box">
                        <label>Password</label>
                        <div
                          className="password-eye"
                          onClick={() => setpasswordShown1(!passwordShown1)}
                        >
                          <em>
                            <img
                              src={
                                passwordShown1
                                  ? withPrefix("assets/img/eye-slash-solid.svg")
                                  : withPrefix("assets/img/eye-solid.svg")
                              }
                              onClick={() => setpasswordShown1(!passwordShown1)}
                              alt="eye"
                            />
                          </em>{" "}
                        </div>
                        <div className="input-out">
                          <div className="input-password-btn">
                            <input
                              id="password"
                              name="password"
                              value={formField.password}
                              onChange={onFormFieldChange}
                              className="password-input"
                              type={passwordShown1 ? "text" : "password"}
                            />
                          </div>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="input-box">
                        <label>Repeat Password</label>
                        <div
                          className="password-eye"
                          onClick={() => setpasswordShown2(!passwordShown2)}
                        >
                          <em>
                            <img
                              src={
                                passwordShown2
                                  ? withPrefix("assets/img/eye-slash-solid.svg")
                                  : withPrefix("assets/img/eye-solid.svg")
                              }
                              onClick={() => setpasswordShown2(!passwordShown2)}
                              alt="eye"
                            />
                          </em>{" "}
                        </div>
                        <div className="input-out">
                          <div className="input-with-btn">
                            <input
                              id="passwordConfirm"
                              name="confirm_password"
                              className="password-input"
                              value={formField.confirm_password}
                              onChange={onFormFieldChange}
                              type={passwordShown2 ? "text" : "password"}
                            />
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>

                  <div className="btn-out">
                    <a
                      href="#"
                      aria-disabled
                      onClick={() => onUpdate()}
                      className="btn"
                    >
                      Update
                    </a>
                  </div>
                </div>
                <div
                  onClick={() => clearFormField()}
                  className="icon-close"
                  data-bs-dismiss="modal"
                >
                  <img
                    src={withPrefix("assets/img/icon-close.png")}
                    alt="icon-close"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SettingsModal;
