import React, { useEffect, useState } from "react";
import { withPrefix } from "gatsby";
import { Pagination } from "@mui/material";
import "../components/css/orderHistory.scss";
import Swal from 'sweetalert2';
import { useDispatch, useSelector } from 'react-redux';
import { getOrderHistory, getOrderDetail, selectOrdersState } from "../sagas/orders/ordersSlice";

const OrderHistory = () => {
  const dispatch = useDispatch();
  const {
    isFetchingOrderHistory,
    isFetchingOrderHistorySuccess,
    isFetchingOrderHistoryFailed,
    OrderHistoryData,
    isGettingOrderDetail,
    isGettingOrderDetailSuccess,
    isGettingOrderDetailFailed,
    OrderDetailData,
  } = useSelector(selectOrdersState);

  const [dataList, setDataList] = useState([]);
  const [page, setPage] = useState(1);
  const [pagination, setPagination] = useState({
    current_page: 1,
    totalRecords: 1,
    pages: 1,
  });
  const [isLoading, setIsLoading] = React.useState(false);
  const per_page = 10;

  useEffect(() => {
    getListing(1);
  }, []);

  const getListing = (page) => {
    dispatch({
      type: getOrderHistory.type,
      payload: {
        PerPage: per_page,
        Page: page,
      }
    });
  };

  useEffect(() => {
    if (isFetchingOrderHistorySuccess) {
      const payload = OrderHistoryData?.data?.map((e) => {
        return { ...e, isOpen: false };
      });
      setDataList(payload ?? []);
      setPage(OrderHistoryData.pages);
      setPagination({
        ...pagination,
        current_page: OrderHistoryData.current_page,
        pages: OrderHistoryData.pages,
        totalRecords: OrderHistoryData.total_records,
      });
    } else if (isFetchingOrderHistoryFailed) {
      console.log('Unable to fetch order history');
    }
  }, [isFetchingOrderHistorySuccess, isFetchingOrderHistoryFailed, OrderHistoryData])

  const [selectedOrderId, setSelectedOrderId] = useState();
  const handleGetOrderDetails = (id) => {
    setSelectedOrderId(id);
    dispatch({
      type: getOrderDetail.type,
      payload: {
        orderId: id
      }
    });
  }

  useEffect(() => {
    if (isGettingOrderDetailSuccess) {
      setDataList((dataList) => dataList.map((obj) => {
        if (obj.id == selectedOrderId) {
          return {
            ...obj,
            details: OrderDetailData,
          }
        }
        
        return obj;
      }));
    } else if (isGettingOrderDetailFailed) {
      Swal.fire({
        icon: 'error',
        text: 'Unable to fetch order details',
        confirmButtonText: 'Retry',
      }).then(async(result) => {
        if (result.isConfirmed) handleGetOrderDetails(selectedOrderId);
      })
    }
  }, [isGettingOrderDetailSuccess, isGettingOrderDetailFailed, OrderDetailData])

  useEffect(() => {
    if (isFetchingOrderHistory || isGettingOrderDetail) {
      setIsLoading(true);
    } else {
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    }
  }, [isFetchingOrderHistory, isGettingOrderDetail])

  const onListClick = (id) => {
    const newDataList = dataList.map((obj) => {
      if (obj.id == id) {
        return {
          ...obj,
          isOpen: !obj.isOpen,
        }
      }
      
      return {
        ...obj,
        isOpen: false,
      }
    });

    setDataList(newDataList);

    const selectedData = newDataList.find((d) => d.id ===id);
    if (selectedData.isOpen === false) return;

    if (!selectedData.details) handleGetOrderDetails(id);
  };

  const handleChange = async (event, page) => {
    event.preventDefault();
    setPage(page);
    setPagination({ ...pagination, current_page: page });
    getListing(page);
  };

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  const dateFormat = (dateString) => {
    var date = new Date(dateString);
    var options = { month: 'long', day: 'numeric' };
    var formattedDate = date.toLocaleDateString('en-US', options);
    return formattedDate;
  }

  const getSize = (variant) => {
    if (!variant.color) return variant.size;
    return `${variant.color} Strap / ${variant.size}`;
  }

  return (
    <div className="col-lg-8 col-md-7 order-history-tab">
      <div className="pagination-tab mt-2">
        <div className="purchase-history">
          <div className="title-bar">
            <div className="credits-heading">
              <h2>Order History</h2>
              <div className="btn-out">
                <a href="/my-creations" className="btn btn-blue">
                  place new order
                </a>
              </div>
            </div>
            <h3>My Orders</h3>
          </div>

          <div className="price-table-out">
            <div className="price-table">
              <ul>
                <li className="table-heading">
                  <div className="col-1">Order Number</div>
                  <div className="col-3">Date</div>
                  <div className="col-4">Charged</div>
                </li>

                {dataList &&
                  dataList.map((item, index) => {
                    return (
                      <li key={index}>
                        <div
                          className={`${
                            item.isOpen
                              ? "price-accord-link active "
                              : "price-accord-link "
                          } `}
                          onClick={() => onListClick(item.id)}
                        >
                          <div className="col-1">{item.orderUuid}</div>
                          <div className="col-3">{item.createdAt}</div>
                          <div className="col-4">{formatter.format(item.billed)}</div>
                          <div className="col-5">
                            <span className="arrow">Arrow</span>
                          </div>
                        </div>

                        <div
                          className={`${
                            item.isOpen ? "" : "price-accord-cont"
                          } `}
                        >
                          <div className="thumbnail">
                            <div className="thumbnails-inn">
                              {item.details ? (
                                <React.Fragment>
                                  {item.details.items?.map((product, pindex) => (
                                    <div className="item-container" key={pindex}>
                                      {item.details.shipments?.filter((s) => s.items.some((si) => si.item_id === product.id)).filter((s) => s.status === 'shipped').map((shipment) => (
                                        <div className="item" key={shipment.id}>
                                          <div className="cn-btn-container">
                                            <button className="cn-btn">
                                              <img
                                                src={withPrefix("assets/img/flag.svg")}
                                                alt="placeholder-2"
                                              />
                                            </button>
                                          </div>
                                          <div>
                                            <h5 className="shipment-no">
                                              Shipment #{item.details.printfulOrderId}-{shipment.id}
                                            </h5>
                                            <p className="shipiedd-text">
                                              shipped on {dateFormat(shipment.shipped_at * 1000)} via {shipment.service}
                                            </p>
                                            <p className="shipiedd-text">
                                              {shipment.tracking_number}
                                            </p>
                                            <a href={shipment.tracking_url} target="_blank" className="see-trac">
                                              See tracking
                                            </a>
                                          </div>
                                        </div>
                                      ))}
                                      <div className="figure-container">
                                        <div className="figure">
                                          <img
                                            src={`${product.image}?version=88`}
                                            alt={product.title}
                                          />
                                        </div>
                                        <div className="order-history-text-container">
                                          <div>
                                            <p className="order-detail-text">
                                              {product.model}
                                            </p>
                                            <p className="product-size">Size: {getSize(product)}</p>
                                          </div>
                                          <div className="last-text">
                                            <p>{product.quantity}</p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  ))}

                                  <div className="shipping-container">
                                    <div className="shipping-add">
                                      <p className="shipping-heading">
                                        SHIPPING ADDRESS:
                                      </p>
                                      <div style={{ marginLeft: '24px' }}>
                                        <p className="shipping-heading-2">{item.details.recipient?.name ?? ''}</p>
                                        <p className="shipping-heading-2">{item.details.recipient?.address1 ?? ''}</p>
                                        {item.details.recipient?.address2 && <p className="shipping-heading-2">{item.details.recipient?.address2 ?? ''}</p>}
                                        <p className="shipping-heading-2">{item.details.recipient?.city ?? ''} {item.details.recipient?.state_code ?? ''} {item.details.recipient?.zip ?? ''}</p>                                        
                                      </div>
                                    </div>
                                    <div className="shipping-add">
                                      <p className="shipping-heading">payment method:</p>
                                      <p className="shipping-heading-3">
                                        {item.details.paymentMethod === 'Link' ? (
                                          <img src={withPrefix("assets/img/stripeLink.svg")} alt="stripe link" style={{ margin: '-2px 10px 0px 0px', height: 20 }} />
                                        ) : <></>}
                                        {item.details.paymentMethod}
                                      </p>
                                    </div>
                                  </div>
                                </React.Fragment>
                              ) : (
                                <img src={withPrefix("assets/img/green-little-balls.gif")} alt="loading" style={{ objectFit: 'contain', width: '100%', height: '200px', marginBottom: '30px' }} />
                              )}
                            </div>
                          </div>
                        </div>
                      </li>
                    );
                  })}
              </ul>
            </div>
          </div>
        </div>
        <div className="pagination-tab-spacing">
          {!isLoading && pagination.totalRecords == 0 ? (
            <div className="pagination" style={{ textAlign: "center" }}>
              no data...
            </div>
          ) : (
            <></>
          )}
          <div className="pagination-align">
            <Pagination
              count={parseInt(pagination.pages)}
              page={parseInt(pagination.current_page)}
              variant="outlined"
              shape="rounded"
              onChange={handleChange}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderHistory;
