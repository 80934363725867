import React, { useEffect, useState } from "react";
import { Pagination } from "@mui/material";
import "../components/css/adminUser.scss";
import { BASE_URL } from "../services/apiUrl";
import { useApplicationContext } from "../../provider";
import { navigate } from "gatsby";
import { setUser, setAccessToken } from "../services/auth";
import { DebounceInput } from 'react-debounce-input';
import { useDispatch, useSelector } from 'react-redux';
import { getUsers, logAsUser, selectAuthState } from "../sagas/auth/authSlice";

const AdminUser = () => {
  const dispatch = useDispatch();
  const {
    isFetchingUsers,
    isFetchingUsersSuccess,
    isFetchingUsersFailed,
    UsersResponse,
    isLoggingAsSuccess,
    LogAsResponse,
  } = useSelector(selectAuthState);

  useEffect(() => {
    if (isFetchingUsers) {
      setIsLoading(true);
    } else {
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    }
  }, [isFetchingUsers])

  const [dataList, setDataList] = useState([]);
  const [page, setPage] = useState(1);
  const [pagination, setPagination] = useState({
    current_page: 1,
    totalRecords: 1,
    pages: 1,
  });
  const [isLoading, setIsLoading] = React.useState(1);
  const per_page = 10;

  const getListing = () => {
    dispatch({
      type: getUsers.type,
      payload: {
        PerPage: per_page,
        Page: page,
        SearchTerm: searchTerm,
      }
    });
  };

  useEffect(() => {
    if (isFetchingUsersSuccess) {
      setDataList(UsersResponse.data);
      setPagination({
        ...pagination,
        current_page: parseInt(UsersResponse.current_page),
        pages: UsersResponse.pages,
        totalRecords: UsersResponse.total_records,
      });
    } else if (isFetchingUsersFailed) {
      console.log('Unable to fetch users list');
    }
  }, [isFetchingUsersSuccess, isFetchingUsersFailed, UsersResponse])

  const handleChange = async (event, page) => {
    event.preventDefault();
    setPage(page);
    setPagination({ ...pagination, current_page: page });
  };

  const { applicationState, setApplicationState, setCreditCount } = useApplicationContext();

  const handleClickLogAs = (userId) => {
    dispatch({
      type: logAsUser.type,
      payload: { logAsUserID: userId }
    })
  }

  useEffect(() => {
    if (isLoggingAsSuccess) {
      setUser(LogAsResponse);
      setAccessToken(LogAsResponse.access_token);
      setApplicationState({
        ...applicationState,
        user_role: LogAsResponse.role,
      });
      setCreditCount(LogAsResponse.credits);
      localStorage.setItem("baseUrl", BASE_URL);
      navigate("/");
    }
  }, [isLoggingAsSuccess, LogAsResponse])

  const [searchTerm, setSearchTerm] = React.useState('');
  const handleSearch = (e) => {
    setPage(1);
    setSearchTerm(e.target.value);
  }
  useEffect(() => {
    getListing();
  }, [searchTerm, page])

  return (
    <div className="col-lg-8 col-md-7 admin-user-tab">
      <div className="pagination-tab mt-2">
        <div className="purchase-history">
          <div className="title-bar">
            {/* <div className="credits-heading">
              <h2>Order History</h2>
              <div className="btn-out">
                <a href="/my-creations" className="btn btn-blue">
                  place new order
                </a>
              </div>
            </div> */}
            <h3>Admin - Auth as User</h3>
            <DebounceInput
              placeholder="start typing..."
              minLength={2}
              debounceTimeout={500}
              onChange={handleSearch} />
          </div>

          <div className="price-table-out">
            <div className="price-table">
              <ul>
                <li className="table-heading">
                  <div className="col-1">Email</div>
                  <div className="col-3">Joined</div>
                  <div className="col-4">Action</div>
                </li>

                {dataList &&
                  dataList.map((user, index) => {
                    return (
                      <li key={index}>
                        <div className="price-accord-link">
                          <div className="col-1">{user.email}</div>
                          <div className="col-3">{user.createdAt}</div>
                          <div className="col-4">
                            <button className="btn btn-blue" onClick={() => handleClickLogAs(user.id)}>AUTH</button>
                          </div>
                        </div>
                      </li>
                    );
                  })}
              </ul>
            </div>
          </div>
        </div>
        <div className="pagination-tab-spacing">
          {!isLoading && pagination.totalRecords == 0 ? (
            <div className="pagination" style={{ textAlign: "center" }}>
              no data...
            </div>
          ) : (
            <></>
          )}
          <div className="pagination-align">
            <Pagination
              count={parseInt(pagination.pages)}
              page={page}
              variant="outlined"
              shape="rounded"
              onChange={handleChange}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminUser;
