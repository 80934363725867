import React, { useState, useEffect } from "react";
import { withPrefix } from "gatsby";
import { navigate } from "gatsby";
import { Helmet } from "react-helmet";
import Header from "../components/header";
import Footer1 from "../components/footer-1";
import { isLoggedIn } from "../services/auth";
import ProfilesTab from "../components/profiles-tab";
import CreditsTab from "../components/credits-tab";
import LedgerTab from "../components/ledger-tab";
import SettingsModal from "../components/settings-modal";
import ProfilesModal from "../components/profiles-modal";
import OrderHistory from "../components/orderHistory-tab";
import AdminUser from "../components/admin-user-tab";
import { useDispatch, useSelector } from 'react-redux';
import { getUserProfile, editUserProfile, selectAuthState } from "../sagas/auth/authSlice";

export default function Layout(props) {
  const dispatch = useDispatch();
  const {
    isGettingUserProfileSuccess,
    isEditingUserProfileSuccess,
    UserProfileResponse,
  } = useSelector(selectAuthState);

  const [tabChange, UpdateTabChange] = useState("credits");
  const [isMobile, setIsMobile] = useState( typeof window !== "undefined" && window.innerWidth < 768);
  useEffect(() => {
    if (!isLoggedIn()) {
      navigate("/signin");
    }
    handleGetUserProfile();
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [state, setState] = useState({
    firstname: "",
    lastname: "",
    email: "",
    zipcode: "",
    country: "",
    createdAt: "",
    profileImage: "",
  });

  const onChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const handleGetUserProfile = async () => {
    dispatch({
      type: getUserProfile.type,
      payload: {},
    });
  };

  useEffect(() => {
    if (isGettingUserProfileSuccess) {
      const { firstname, lastname, email, zipcode, country, profileImage, createdAt } = UserProfileResponse;

      setState({
        firstname: firstname,
        lastname: lastname,
        email: email,
        profileImage: profileImage,
        zipcode: zipcode,
        country: country,
        createdAt: createdAt,
      });
    }
  }, [isGettingUserProfileSuccess, UserProfileResponse])

  const onSubmitProfileChange = async () => {
    dispatch({
      type: editUserProfile.type,
      payload: {
        email: state.email,
        firstname: state.firstname,
        lastname: state.lastname,
        profileImage: state.profileImage,
        zipcode: state.zipcode,
      },
    });
  };

  useEffect(() => {
    if (isEditingUserProfileSuccess) {
      setState({
        ...state,
        firstname: UserProfileResponse.firstname,
        lastname: UserProfileResponse.lastname,
        profileImage: UserProfileResponse.profileImage,
        zipcode: UserProfileResponse.zipcode,
      });


      let user = JSON.parse(localStorage.getItem("currentUser") || "{}");
      user.profileImage = UserProfileResponse.profileImage;
      localStorage.setItem("currentUser", JSON.stringify(user));
    }
  }, [isEditingUserProfileSuccess, UserProfileResponse])

  const currentUser = typeof window !== "undefined" && JSON.parse(localStorage.getItem("currentUser") || "{}");
  const superAdminOnly = currentUser?.role?.toLowerCase() === "superadmin";

  const naviationElement = (
    <div className="profile-tab-links2">
      <ul className="nav">
        <li>
          <a
            onClick={() => UpdateTabChange("credits")}
            className={`${tabChange === "credits" ? "active" : null}`}
            href="#credits"
            data-bs-toggle="tab"
          >
            {" "}
            Credits{" "}
          </a>
        </li>
        <li>
          <a
            onClick={() => UpdateTabChange("ledger")}
            className={`${tabChange === "ledger" ? "active" : null}`}
            href="#ledger"
            data-bs-toggle="tab"
          >
            {" "}
            Ledger{" "}
          </a>
        </li>
        <li>
          <a
            onClick={() => UpdateTabChange("order-history")}
            href="#order-history"
            data-bs-toggle="tab"
            className={`${tabChange === "order-history" ? "active" : null}`}
          >
            Order History
          </a>
        </li>
        <li>
          <a
            onClick={() => UpdateTabChange("settings")}
            href="#setting"
            data-bs-toggle="modal"
            className={`${tabChange === "settings" ? "active" : null}`}
          >
            Settings
          </a>
        </li>
        {superAdminOnly && (
          <li>
            <a
              onClick={() => UpdateTabChange("admin-user")}
              href="#admin-user"
              data-bs-toggle="tab"
              className={`${tabChange === "admin-user" ? "active" : null}`}
            >
              Admin
            </a>
          </li>
        )}
      </ul>
    </div>
  );
  return (
    <>
      <Helmet>
        <script
          src={withPrefix("assets/js/custom.js")}
          type="text/javascript"
        />
        <script
          src={withPrefix(
            "assets/plugins/resize-drag-rotate-box-modeling/box-modeling-custom.js"
          )}
          type="text/javascript"
        />
      </Helmet>
      <div className="container-main" id="page">
        <Header></Header>
        <main className="content-main">
          <div className="profile-tabs">
            <div className="container">
              <h1>Profile</h1>
              {!isMobile ? naviationElement : null}
              <div className="tab-content">
                <div className="tab-pane active" id="credits">
                  <div className="profile-info">
                    <div className="row">
                      <ProfilesTab
                        state={state}
                        onChangeFunction={handleGetUserProfile}
                      ></ProfilesTab>
                      {isMobile ? naviationElement : null}
                      <CreditsTab state={tabChange}></CreditsTab>
                    </div>
                  </div>
                </div>
                <div className="tab-pane" id="ledger">
                  <div className="profile-info">
                    <div className="row">
                      <ProfilesTab
                        state={state}
                        onChangeFunction={handleGetUserProfile}
                      ></ProfilesTab>
                      {isMobile ? naviationElement : null}
                      <LedgerTab state={tabChange}></LedgerTab>
                    </div>
                  </div>
                </div>
                <div className="tab-pane" id="order-history">
                  <div className="profile-info">
                    <div className="row">
                      <ProfilesTab
                        state={state}
                        onChangeFunction={handleGetUserProfile}
                      ></ProfilesTab>
                      {isMobile ? naviationElement : null}
                      <OrderHistory state={tabChange}></OrderHistory>
                    </div>
                  </div>
                </div>
                {superAdminOnly && (
                  <div className="tab-pane" id="admin-user">
                    <div className="profile-info">
                      <div className="row">
                        <ProfilesTab
                          state={state}
                          onChangeFunction={handleGetUserProfile}
                        ></ProfilesTab>
                        {isMobile ? naviationElement : null}
                        <AdminUser state={tabChange}></AdminUser>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <ProfilesModal
            state={state}
            onChangeProp={onChange}
            setState={setState}
            onSubmitProfileChangeProp={onSubmitProfileChange}
          ></ProfilesModal>
          <SettingsModal
            state={state}
            onChangeFunction={handleGetUserProfile}
          ></SettingsModal>
        </main>
        <Footer1></Footer1>
      </div>
    </>
  );
}
