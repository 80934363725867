import React, { useEffect, useState } from "react";
import { withPrefix, navigate } from "gatsby";
import Pagination from "@mui/material/Pagination";
import Swal from 'sweetalert2';
import { useApplicationContext } from "../../provider";
import { useDispatch, useSelector } from 'react-redux';
import { getLedgerHistory, getLedgerDetails, selectCreditsState } from "../sagas/credits/creditsSlice";

const LedgerTab = () => {
  const dispatch = useDispatch();
  const { 
    isFetchingLedgerHistory, 
    isFetchingLedgerHistorySuccess, 
    isFetchingLedgerHistoryFailed, 
    LedgerHistoryData,
    isGettingLedgerDetails, 
    isGettingLedgerDetailsSuccess, 
    isGettingLedgerDetailsFailed, 
    LedgerDetailsData,
  } = useSelector(selectCreditsState);

  const { applicationState, setApplicationState } = useApplicationContext();

  const [dataList, setDataList] = useState([]);
  const [page, setPage] = useState(1);
  const [pagination, setPagination] = useState({
    current_page: 1,
    totalRecords: 1,
    pages: 1,
  });
  const [isLoading, setIsLoading] = React.useState(1);
  const per_page = 10;

  useEffect(() => {
    getListing(1);
  }, []);

  const getListing = (page) => {
    dispatch({
      type: getLedgerHistory.type,
      payload: {
        perPage: per_page,
        page,
      }
    });
  };

  useEffect(() => {
    if (isFetchingLedgerHistorySuccess) {
      let payload =
        LedgerHistoryData &&
        LedgerHistoryData.data.map((e) => {
          return { ...e, isOpen: false };
        });
      setDataList(payload);
      setPage(LedgerHistoryData.pages);
      setPagination({
        ...pagination,
        current_page: LedgerHistoryData.current_page,
        pages: LedgerHistoryData.pages,
        totalRecords: LedgerHistoryData.total_records,
      });
    } else if (isFetchingLedgerHistoryFailed) {
      console.log('Unable to fetch ledger history');
    }
  }, [isFetchingLedgerHistorySuccess, isFetchingLedgerHistoryFailed, LedgerHistoryData])

  useEffect(() => {
    if (isFetchingLedgerHistory || isGettingLedgerDetails) {
      setIsLoading(true);
    } else {
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    }
  }, [isFetchingLedgerHistory, isGettingLedgerDetails])

  const handleChange = async (event, page) => {
    event.preventDefault();
    setPage(page);
    setPagination({ ...pagination, current_page: page });
    getListing(page);
  };

  const [selectedId, setSelectedId] = useState();
  const handleGetLedgerDetails = (id) => {
    setSelectedId(id);
    dispatch({
      type: getLedgerDetails.type,
      payload: { id },
    });
  }

  useEffect(() => {
    if (isGettingLedgerDetailsSuccess) {
      setDataList((dataList) => dataList.map((obj) => {
        if (obj.id == selectedId) {
          return {
            ...obj,
            details: LedgerDetailsData,
          }
        }
        
        return obj;
      }));
    } else if (isGettingLedgerDetailsFailed) {
      Swal.fire({
        icon: 'error',
        text: 'Unable to fetch ledger details',
        confirmButtonText: 'Retry',
      }).then(async(result) => {
        if (result.isConfirmed) handleGetLedgerDetails(selectedId);
      })
    }
  }, [isGettingLedgerDetailsSuccess, isGettingLedgerDetailsFailed, LedgerDetailsData])

  const onListClick = (id) => {
    const newDataList = dataList.map((obj) => {
      if (obj.id == id) {
        return {
          ...obj,
          isOpen: !obj.isOpen,
        }
      }
      
      return {
        ...obj,
        isOpen: false,
      }
    });

    setDataList(newDataList);

    const selectedData = newDataList.find((d) => d.id ===id);
    if (selectedData.isOpen === false) return;

    if (!selectedData.details) handleGetLedgerDetails(id);
  };

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  const openMerchifyModal = (art) => {
    Swal.fire({
      html: `<img src="${art.image}" alt="${art.image}" style="background: #fff" />`,
      confirmButtonText: 'Merchify',
      confirmButtonColor: '#5757FF',
      showCancelButton: true,
      cancelButtonText: 'Close',
    }).then((result) => {
      if (result.isConfirmed) {
        const merchifyArr = [];

        merchifyArr.push({
          id_val: 0,
          original: art.image,
          upscaled: art.image,
        });
        localStorage.setItem("mergify", JSON.stringify(merchifyArr));
        localStorage.setItem("job_id", ',,,,,');

        if (art.carousel_uID) {
          setApplicationState({
            ...applicationState,
            mycreationsSelectedCount: 1,
          });

          localStorage.setItem("selectedCarouselUuids", JSON.stringify([art.carousel_uID]));
          navigate("/my-creations-view");
        } else {
          navigate("/select-merch");
        }
      }
    })
  }

  return (
    <div className="col-lg-8 col-md-7">
      <div className="pagination-tab mt-2">
        <div className="purchase-history">
          <div className="title-bar">
            <div className="credits-heading">
              <h2>My Ledger</h2>
              <div className="btn-out">
                <a href="#" className="btn btn-blue">
                  Buy credits
                </a>
              </div>
            </div>
            <h3>Credit Spending Log</h3>
          </div>
          <div className="price-table-out">
            <div className="price-table">
              <ul>
                <li className="table-heading">
                  <div className="col-1">Credits</div>
                  <div className="col-2">Date</div>
                  <div className="col-3">Cost</div>
                  <div className="col-4">Billed</div>
                </li>
                {dataList &&
                  dataList.map((item, index) => {
                    return (
                      <li key={index}>
                        <div
                          className={`${
                            item.isOpen
                              ? "price-accord-link active "
                              : "price-accord-link "
                          } `}
                          onClick={() => onListClick(item.id)}
                        >
                          <div className="col-1">
                            {item.credtisCount} credits
                          </div>
                          <div className="col-2">{item.createdAt}</div>
                          <div className="col-3">{formatter.format(item.cost)}</div>
                          <div className="col-4">{formatter.format(item.billed)}</div>
                          <div className="col-5">
                            <span className="arrow">Arrow</span>
                          </div>
                        </div>

                        <div
                          className={`${
                            item.isOpen ? "" : "price-accord-cont"
                          } `}
                        >
                          <div className="thumbnails">
                            <div className="thumbnails-inn" style={{ minHeight: '96px' }}>
                              {item.details?.artImages ?
                                item.details.artImages.map((artImage, aindex) => {
                                  return (
                                    <div className="item" key={aindex} onClick={() => openMerchifyModal(artImage)}>
                                      <div className="figure" style={{ cursor: "pointer" }}>
                                        <img src={artImage.image} alt={artImage.image} />
                                      </div>
                                    </div>
                                  );
                                }) : (
                                  <img src={withPrefix("assets/img/green-little-balls.gif")} alt="loading" style={{ objectFit: 'contain', width: '100%', height: '96px', marginBottom: '30px' }} />
                                )}
                            </div>
                          </div>
                        </div>
                      </li>
                    );
                  })}
              </ul>
            </div>
          </div>
        </div>
        <div className="pagination-tab-spacing">
          {!isLoading && pagination.totalRecords == 0 ? (
            <div className="pagination" style={{ textAlign: "center" }}>
              no data...
            </div>
          ) : (
            <></>
          )}
          <div className="pagination-align">
            <Pagination
              count={parseInt(pagination.pages)}
              page={parseInt(pagination.current_page)}
              variant="outlined"
              shape="rounded"
              onChange={handleChange}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LedgerTab;
