import React, { useEffect, useState } from "react";
import Pagination from "@mui/material/Pagination";
import { useApplicationContext } from "../../provider";
import Swal from 'sweetalert2';
import { useDispatch, useSelector } from 'react-redux';
import { getCreditHistory, getCreditDetails, selectCreditsState } from "../sagas/credits/creditsSlice";

const CreditsTab = () => {
  const dispatch = useDispatch();
  const { 
    isFetchingCreditHistory, 
    isFetchingCreditHistorySuccess, 
    isFetchingCreditHistoryFailed, 
    CreditHistoryData,
    isGettingCreditDetails, 
    isGettingCreditDetailsSuccess, 
    isGettingCreditDetailsFailed, 
    CreditDetailsData,
  } = useSelector(selectCreditsState);

  const [dataList, setDataList] = useState([]);
  const [pagination, setPagination] = useState({
    current_page: 1,
    totalRecords: 1,
    pages: 1,
  });
  const { creditCount } = useApplicationContext();
  const [isLoading, setIsLoading] = React.useState(1);
  const per_page = 10;

  useEffect(() => {
    getListing(1);
  }, []);

  const getListing = (page) => {
    dispatch({
      type: getCreditHistory.type,
      payload: {
        perPage: per_page,
        page,
      }
    });
  };

  useEffect(() => {
    if (isFetchingCreditHistorySuccess) {
      let payload =
        CreditHistoryData &&
        CreditHistoryData.data.map((e) => {
          return { ...e, isOpen: false };
        });
      setDataList(payload);
      setPagination({
        ...pagination,
        current_page: CreditHistoryData.current_page,
        pages: CreditHistoryData.pages,
        totalRecords: CreditHistoryData.total_records,
      });
    } else if (isFetchingCreditHistoryFailed) {
      console.log('Unable to fetch credit history');
    }
  }, [isFetchingCreditHistorySuccess, isFetchingCreditHistoryFailed, CreditHistoryData])

  useEffect(() => {
    if (isFetchingCreditHistory || isGettingCreditDetails) {
      setIsLoading(true);
    } else {
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    }
  }, [isFetchingCreditHistory, isGettingCreditDetails])

  const handleChange = async (event, page) => {
    event.preventDefault();
    setPagination({ ...pagination, current_page: page });
    getListing(page);
  };

  const [selectedId, setSelectedId] = useState();
  const handleGetCreditDetails = (id) => {
    setSelectedId(id);
    dispatch({
      type: getCreditDetails.type,
      payload: { id },
    });
  }

  useEffect(() => {
    if (isGettingCreditDetailsSuccess) {
      setDataList((dataList) => dataList.map((obj) => {
        if (obj.id == selectedId) {
          return {
            ...obj,
            details: CreditDetailsData,
          }
        }
        
        return obj;
      }));
    } else if (isGettingCreditDetailsFailed) {
      Swal.fire({
        icon: 'error',
        text: 'Unable to fetch credit details',
        confirmButtonText: 'Retry',
      }).then(async(result) => {
        if (result.isConfirmed) handleGetCreditDetails(selectedId);
      })
    }
  }, [isGettingCreditDetailsSuccess, isGettingCreditDetailsFailed, CreditDetailsData])

  const onListClick = (id) => {
    const newDataList = dataList.map((obj) => {
      if (obj.id == id) {
        return {
          ...obj,
          isOpen: !obj.isOpen,
        }
      }
      
      return {
        ...obj,
        isOpen: false,
      }
    });

    setDataList(newDataList);

    const selectedData = newDataList.find((d) => d.id ===id);
    if (selectedData.isOpen === false) return;

    if (!selectedData.details) handleGetCreditDetails(id);
  };

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  return (
    <div className="col-lg-8 col-md-7">
      <div className="pagination-tab mt-2">
        <div className="purchase-history">
          <div className="title-bar">
            <div className="credits-heading">
              <h2>Credits : {creditCount} </h2>
              <div className="btn-out">
                <a href="/pricing-options" className="btn btn-blue">
                  {" "}
                  Buy credits{" "}
                </a>
              </div>
            </div>
            <h3>Purchase History</h3>
          </div>
          <div className="price-table-out">
            <div className="price-table alt">
              <ul>
                <li className="table-heading">
                  <div className="col-1">Credits</div>
                  <div className="col-2">Date</div>

                  <div className="col-3">Value</div>
                  <div className="col-4">Billed</div>
                </li>
                {dataList &&
                  dataList.map((item, index) => {
                    return (
                      <li key={index}>
                        <div
                          className={`${
                            item.isOpen
                              ? "price-accord-link active"
                              : "price-accord-link "
                          } `}
                          onClick={() => onListClick(item.id)}
                        >
                          <div className="col-1">
                            {item.credits} Credits added to account
                          </div>
                          <div className="col-2">{item.createdAt}</div>
                          <div className="col-3">0.20 cPC</div>
                          <div className="col-4">{formatter.format(item.billed)}</div>
                          <div className="col-5">
                            <span className="arrow">Arrow</span>
                          </div>
                        </div>

                        <div
                          className={`${
                            item.isOpen ? "" : "price-accord-cont"
                          } `}
                        >
                          <div className="order-details">
                            <div className="box">
                              {item.details && (
                                <React.Fragment>
                                  <div className="left-txt">
                                    ORDER # &nbsp; {item.details.order_no}
                                  </div>
                                  <div className="right-txt">
                                    STATUS &nbsp;{" "}
                                    <span
                                      className={`${
                                        item.details.status === "COMPLETE"
                                          ? "green"
                                          : "red"
                                      }`}
                                    >
                                      {item.details.status == "COMPLETE"
                                        ? "COMPLETE"
                                        : "NOT COMPLETE"}
                                    </span>
                                  </div>
                                </React.Fragment>
                              )}
                            </div>
                          </div>
                        </div>
                      </li>
                    );
                  })}
              </ul>
            </div>
          </div>
        </div>
        <div className="pagination-tab-spacing">
          {!isLoading && pagination.totalRecords == 0 ? (
            <div className="" style={{ textAlign: "center" }}>
              no data...
            </div>
          ) : (
            <></>
          )}
          <div className="pagination-align">
            <Pagination
              count={parseInt(pagination.pages)}
              page={parseInt(pagination.current_page)}
              variant="outlined"
              shape="rounded"
              onChange={handleChange}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreditsTab;
