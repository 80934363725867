import * as React from 'react'
import { withPrefix, navigate } from "gatsby";

const ProfilesTab = ({ state, onChangeFunction }) => {
    const GoToMyStorefronts = (e) => {
        e.preventDefault();
        navigate("/my-storefronts");
    }

    return (
        <div className="col-lg-4 col-md-5">
            <div className="profile-box">
                <div className="icon-user">
                    <a href="#edit-profile" data-bs-toggle="modal" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%' }}>
                        <img src={state.profileImage ? `${state.profileImage}?version=104` : withPrefix("assets/img/icon-user.svg")} style={{ borderRadius: '104px', maxWidth: 104, maxHeight: 104 }} alt="profile" />
                    </a>
                </div>
                <div className="aside">
                    <div className="name">{state.firstname} {state.lastname}</div>
                    <p>
                        <a href="mailto:firsnamelastname@gmail.com"> {state.email} </a>
                        <br /> {state.country !== null ? <em className="fa fa-map-marker-alt" /> : null} {state.country}  <br /> Joined: {state.createdAt} </p>
                    <div className="btn-out text-center">
                        <a href="#" className="btn" onClick={GoToMyStorefronts}> My Storefronts </a>
                    </div>
                </div>
                <div className="icon-edit" onClick={onChangeFunction}>
                    <a href="#edit-profile" data-bs-toggle="modal"> <img src={withPrefix("assets/img/icon-edit.svg")} alt="icon-edit" /> </a>
                </div>
            </div>
        </div>
    )
}

export default ProfilesTab