import * as React from 'react';
import { useState, useEffect } from "react";
import { withPrefix } from "gatsby";
import "../components/css/profile-modal.css";
import CropImageModal from "./CropImageModal";
import { useDispatch, useSelector } from 'react-redux';
import { postImage, selectImageState } from "../sagas/image/imageSlice";

const ProfilesModal = ({ state, setState, onChangeProp, onSubmitProfileChangeProp }) => {
    const dispatch = useDispatch();
    const { isPostingImage, isPostingImageSuccess, isPostingImageFailed, PostImageResponse } = useSelector(selectImageState);

    const [showAnimationflag, setShowAnimationflag] = React.useState(false);

    const [cropModalOpen, setCropModalOpen] = useState(false);
    const [cropper, setCropper] = useState(<any />);
    const [picture, setPicture] = useState("");
  
    const getCropData = () => {
      if (typeof cropper !== "undefined") {
        uploadCroppedImg(cropper.getCroppedCanvas().toDataURL());
        setCropModalOpen(false);
      }
    };
    const [profileImageUpdated, setProfileImageUpdated] = useState(false);
    const uploadCroppedImg = (base64data) => {
        var real_str = base64data.slice(22);

        dispatch({
            type: postImage.type,
            payload: { base64Url: real_str },
        });
    };

    useEffect(() => {
        if (isPostingImage) {
            setShowAnimationflag(true);
        } else if (isPostingImageSuccess) {
            setState({ ...state, profileImage: PostImageResponse.url });
            setProfileImageUpdated(true);
            setShowAnimationflag(false);
        } else if (isPostingImageFailed) {
            setShowAnimationflag(false);
        }
    }, [isPostingImage, isPostingImageSuccess, isPostingImageFailed, PostImageResponse])

    React.useEffect(() => {
        if (profileImageUpdated && !!state.profileImage) onSubmitProfileChangeProp();
    }, [state.profileImage, profileImageUpdated])

    const runPipeline = (e) => {
        if (!e.target.files || e.target.files.length == 0) {
          return;
        }
        setCropModalOpen(true);
        const file = e.target.files[0];
    
        if (!file) return;
        var reader = new FileReader();
        reader.readAsDataURL(file);
    
        reader.onloadend = async function () {
            setPicture(reader.result);
        };
    };

    const inputRef = React.useRef(null);
    const onIconClick = () => {
        inputRef?.current.click();
    };

    return (
        <div className="profile-modal">
            {showAnimationflag && (
                <div className="new_gif" style={{ display: "flex", position: 'fixed', top: 0, zIndex: 1061 }}>
                    <img
                        src={withPrefix("assets/img/green-little-balls.gif")}
                        className="img-fluid mw_50"
                        alt="loading"
                    />
                </div>
            )}

            <div className="modal fade" id="edit-profile" role="dialog">
                <div className="modal-dialog modal-dialog-centered" role="document" >
                    <div className="modal-content">
                        <div className="profile-popup">
                            <div className="box">
                                <div className="icon-user">
                                    <img src={state.profileImage ? `${state.profileImage}?version=104` : withPrefix("assets/img/icon-user.svg")} alt="icon-user" style={{ borderRadius: '104px', maxWidth: 104, maxHeight: 104 }} />{" "}
                                    <span className="icon-edit">
                                        <a href="#" onClick={onIconClick}>
                                            <input
                                                type="file"
                                                id="image-input"
                                                onChange={runPipeline}
                                                accept=".jpeg, .png, .jpg, .webp"
                                                style={{ display: 'none' }}
                                                ref={inputRef}
                                            />
                                            <img src={withPrefix("assets/img/icon-edit.svg")} alt="icon-edit" />
                                        </a>
                                    </span>
                                </div>
                                <h3>Edit Profile</h3>
                                <div className="form-field">
                                    <ul>
                                        <li>
                                            <div className="input-box">
                                                <label>First Name</label>                                             
                                                <div className="input-out">
                                                    <input className='input-first-name' id="firstname"  name="firstname" type="text" onChange={onChangeProp} value={state.firstname} />
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="input-box">
                                                <label>Last Name</label>
                                                <div className="input-out">
                                                    <input className='input-first-name' id="lastname" name="lastname" type="text" onChange={onChangeProp} value={state.lastname} />
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="input-box">
                                                <label>Zip Code</label>
                                                <div className="input-out">
                                                    <input className='input-first-name' id="zipcode" name="zipcode" type="text" onChange={onChangeProp} value={state.zipcode} />
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                    <div className="btn-out" onClick={onSubmitProfileChangeProp} data-bs-dismiss="modal">
                                        <a href="#" className="btn">Save</a>
                                    </div>
                                </div>
                                <div className="icon-close" data-bs-dismiss="modal">
                                    <img src={withPrefix("assets/img/icon-close.png")} alt="icon-close" />
                                </div>
                            </div>
                            <div className="cancel-btn">
                                <a href="#" data-bs-dismiss="modal">Cancel</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <CropImageModal 
                cropModalOpen={cropModalOpen} 
                setCropModalOpen={setCropModalOpen} 
                picture={picture}
                cropper={cropper} 
                setCropper={setCropper}
                getCropData={getCropData}
                isCircleArea={true}
            />
        </div>
    )
}

export default ProfilesModal